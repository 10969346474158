<template>
  <div class="product-sort">
    <head-nav></head-nav>
    <product-img></product-img>
    <div>
      <div class="product-sort-listTitle">
        <router-link
          tag="span"
          to="/product-sort"
        >
          <i class="iconfont icon-zuobianjiantou"></i>
        </router-link>{{productInfo.name}}

      </div>
    </div>
    <div>
    </div>
    <product-details @tab-change="onTabChange"></product-details>
    <product-introduce
      v-if="acticteTab === 0"
      :value="productInfo.Introduction"
    >
    </product-introduce>

    <product-specs
      v-if="acticteTab === 1"
      :value="productInfo.mobileSpecification"
    >
    </product-specs>

    <product-case
      v-if="acticteTab === 2"
      :value="productInfo.successfulCases"
    >
    </product-case>

    <product-download
      v-if="acticteTab === 3"
      :value="productInfo.productMaterials"
    >
    </product-download>

    <footers></footers>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import HeadNav from './head-nav';
import productImg from './product-img';
import ProductDetails from './product-details';
import ProductSpecs from './product-specs';
import ProductIntroduce from './product-introduce';
import ProductDownload from './product-download';
import ProductCase from './product-case';
import Footers from './footers';

export default {
  props: {
    listId: {
      type: String,
      required: true,
    },
  },
  components: {
    HeadNav,
    productImg,
    ProductDetails,
    ProductSpecs,
    ProductIntroduce,
    ProductDownload,
    ProductCase,
    Footers,
  },
  apollo: {
    productInfo: {
      query() {
        if (this.$i18n.locale === 'cn') {
          return gql`
            query($id: ID!) {
              productInfo: Product(where: { id: $id }) {
                id
                name
                Introduction
                mobileSpecification
                productMaterials {
                  id
                  material {
                    originalFilename
                    publicUrl
                  }
                  createdAt
                }

                successfulCases {
                  id
                  image {
                    publicUrl
                  }
                  name
                }
                en_Product {
                  id
                }
              }
            }
          `;
        }
        return gql`
          query($id: ID!) {
            productInfo: EnProduct(where: { id: $id }) {
              id
              name
              Introduction
              mobileSpecification
              productMaterials {
                id
                material {
                  originalFilename
                  publicUrl
                }
                createdAt
              }
              successfulCases {
                id
                image {
                  publicUrl
                }
                name
              }
              product {
                id
              }
            }
          }
        `;
      },
      variables() {
        return {
          id: this.listId,
        };
      },
    },
  },
  data() {
    return {
      acticteTab: 0,
      productInfo: {
        Introduction: '',
        mobileSpecification: '',
        productMaterials: '',
        successfulCases: '',
      },
    };
  },
  methods: {
    onTabChange(activateTab) {
      this.acticteTab = activateTab;
    },
  },
  watch: {
    '$i18n.locale': {
      handler(newValue, oldValue) {
        if (oldValue === 'cn') {
          this.$router.replace({
            params: {
              listId: this.productInfo.en_Product.id,
            },
          });
        } else if (oldValue === 'en') {
          this.$router.replace({
            params: {
              listId: this.productInfo.product.id,
            },
          });
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.product-sort-listTitle {
  width: 100%;
  text-align: center;
  justify-content: center;
  height: 80px;
  line-height: 80px;
  background: #ffbf00;
  position: relative;

  .iconfont {
    position: absolute;
    top: 0px;
    left: 30px;
    font-size: 36px;
  }
}
</style>
