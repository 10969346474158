<template>
  <div class="download">
    <div class="product-download">
      <div
        class="product-download-list"
        v-for="(item, index) in value"
        :key="index"
      >
        <div class="product-download-list-left">
          <span class="product-download-list-left-info">{{item.material.originalFilename}}</span>
        </div>
        <div class="product-download-list-right">
          <img
            src="@/assets/image/product/download.png"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.download {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .product-download {
    margin: 30px 30px 100px 30px;
    background: #f8f8f8;

    &-list {
      display: flex;
      flex: 1;
      justify-content: space-around;
      background: #fff;
      border: 1px solid #eee;
      border-radius: 10px;
      margin-top: 20px;
      width: 100%;

      &-left {
        width: 560px;
        height: 120px;
        line-height: 120px;

        &-info {
          display: block;
          width: 460px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin: 0 30px;
        }
      }

      &-right {
        width: 120px;
        height: 120px;
        text-align: center;
        line-height: 80px;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        background: #ffbf00;

        img {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
}

@media screen and (max-width: 320px) {
  .download .product-download-list-left {
    width: 480px;
  }
  .download .product-download-list-left-info {
    width: 380px;
  }
  .download .product-download-list-right {
    width: 160px;
  }
}
</style>
