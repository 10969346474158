<template>
  <div class="product-details">
    <div
      class="product-details-list"
      v-for="(item, index) in list"
      :key="index"
      @click="option(index)"
    >
      {{ item.title }}
      <i
        class="iconfont"
        :class="page == index ? ' icon-hengxian' : ''"
      ></i>
    </div>
  </div>
</template>

<script>
import '@/assets/icon/iconfont.css';

export default {
  data() {
    return {
      page: 0,
      list: [
        {
          id: 1,
          title: '介绍',
          icon: false,
        },
        {
          id: 2,
          title: '规格',
          icon: false,
        },
        {
          id: 3,
          title: '案例',
          icon: false,
        },
        {
          id: 4,
          title: '下载',
          icon: false,
        },
      ],
    };
  },
  methods: {
    option(index) {
      this.$emit('tab-change', index);
      this.list[index].icon = !this.list[index].icon;
      this.page = index;
    },
  },
};
</script>

<style lang="scss" scoped>
.product-details {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: space-around;
  background: #fff;
  height: 80px;
  line-height: 80px;
  border: 1px solid #eeeeee;

  &-list {
    text-align: center;
    position: relative;

    .iconfont {
      position: absolute;
      left: -10%;
      top: 36px;
      font-size: 60px;
      font-weight: 600;
      color: #ffbf00;
    }
  }
}
</style>
