<template>
  <div class="introduce">
    <div class="product-introduce">
      <div
        class="product-introduce-list"
        v-html="value"
      >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.introduce {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .product-introduce {
    background: #f8f8f8;
    padding: 50px 30px 90px 30px;

    &-list {
      width: 100%;
      line-height: 40px;
      font-size: 28px;
    }
  }
}
</style>
