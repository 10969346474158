<template>
  <div>
    <img
      width="100%"
      v-for="(src, index) in imageSrcList"
      :key="index"
      :src="src"
      alt=""
    >
  </div>
</template>

<script>
export default {
  props: {
    html: {
      type: String,
      default: '',
    },
  },
  computed: {
    imageSrcList() {
      if (!this.html) {
        return [];
      }
      const iamgeRegx = /src="([^"]*?)"/gi;
      const target = this.html.match(iamgeRegx);
      const allUrlList = target.map((item) => item.slice(5, -1));
      console.log(allUrlList);
      return allUrlList;
    },
  },
};
</script>

<style>
</style>
