<template>
  <div class="case">
    <div class="product-case">
      <div
        class="product-case-list"
        v-for="(item, index) in value"
        :key="index"
      >
        <img
          class="product-case-list-img"
          :src="item.image.publicUrl"
          alt=""
        />
        <div class="product-case-list-info">{{ item.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.case {
  background: #eee;
  position: relative;
  top: 0;
  left: 0;
  height: 100%;
}
.product-case {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 30px;
  padding: 34px 0;
  border-radius: 10px;
  overflow: hidden;

  &-list {
    width: 320px;
    height: 230px;
    border-radius: 40px;
    position: relative;
    margin-top: 20px;

    &-img {
      width: 100%;
      height: 100%;
    }

    &-info {
      width: 100%;
      text-align: center;
      height: 60px;
      line-height: 60px;
      background: #000;
      opacity: 0.7;
      position: absolute;
      bottom: 0;
      left: 0;
      color: #fff;

      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-all;
    }
  }
}

@media screen and (max-width: 320px) {
  .product-case {
    margin: 0 20px;
    padding-bottom: 50px;

    &-list {
      width: 280px;
      height: 180px;
      margin-top: 40px;

      &-info {
        bottom: -20px;
      }

      &-img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
