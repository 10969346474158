<template>
  <div class="specs-box">
    <pure-image :html="value"></pure-image>
  </div>
</template>

<script>
import productContent from '@/mock/content';
import pureImage from '../components/pure-image';

export default {
  components: {
    pureImage,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      productContent,
    };
  },
};
</script>

<style lang="scss" scoped>
.specs-box {
  ::v-deep p {
    background: #f8f8f8;
    padding-left: 4px;
    color: #555;
    font-size: 24px;
  }

  ::v-deep table {
    border: 1px solid #eeeeee;
    width: 100% !important;

    tbody {
      tr {
        background: #f8f8f8;
        line-height: 35px;

        td {
          padding-left: 4px;
          color: #666;
        }
      }

      tr:nth-child(2n + 2) {
        background: #fff;
      }
    }
  }
}
</style>
